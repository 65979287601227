<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
    <g id="Icona_Prestito_Personale" transform="translate(0.494)">
      <rect id="icon_spacer" width="60" height="60" transform="translate(-0.494 0)" fill="none"/>
      <path id="bag" d="M25.627,60q-7.269,0-14.542-.013h-.2A10.879,10.879,0,0,1,0,49.271v-.4C.1,38.66,4.158,29.617,12.408,21.231c1.124-1.141,2.312-2.206,3.569-3.334l.124-.111c.558-.5,1.128-1.013,1.705-1.544l.6-.55L11.611,5.424c-.953-1.438-.969-1.465.442-2.667A9.31,9.31,0,0,1,18.165.293a9.088,9.088,0,0,1,3.212.612,12.64,12.64,0,0,0,5.012,1.027A12.99,12.99,0,0,0,32.175.566a5.879,5.879,0,0,1,6.342.847c1.366,1.03,1.353,1.058.609,2.445-1.652,3.091-3.309,6.171-4.9,9.116l-1.288,2.394.483.5c.958.992,1.924,1.956,2.883,2.912,2.171,2.162,4.187,4.169,6.028,6.4a36.835,36.835,0,0,1,8.935,23.7A10.921,10.921,0,0,1,40.546,59.987h-.369Q32.891,60,25.627,60m8.883-1.148q2.943,0,5.885-.017h.128a9.319,9.319,0,0,0,9.266-7.892,24.585,24.585,0,0,0-.168-7.263c-1.4-8.767-5.8-16.352-13.859-23.863-2.084-2.1-2.973-3.323-4.961-3.323l-9.82.015c-1.841,0-3.133.255-9.144,6.975l-.333.367c-.232.256-.466.515-.684.777-6.339,7.457-9.5,15.282-9.656,23.919a9.833,9.833,0,0,0,9.282,10.286c.173.01.346.014.521.014.12,0,.242,0,.362-.007,1.534.006,3.115.008,4.694.008l9.478-.007ZM18.135,1.5a7.52,7.52,0,0,0-5.3,2.225l-.54.55,2.043,3.089c1.492,2.265,3.037,4.608,4.629,6.931a2.9,2.9,0,0,0,2.076,1.082c1.51.031,2.977.043,4.455.043q2.311,0,4.622-.043a2.54,2.54,0,0,0,1.908-.966c1.629-2.906,3.1-5.647,4.663-8.548l1.251-2.333.421-.8-.34-.261A6.155,6.155,0,0,0,34.565,1.2a7.917,7.917,0,0,0-3.388.939,10.886,10.886,0,0,1-4.718,1.2,8.572,8.572,0,0,1-3.7-.863A12.4,12.4,0,0,0,19.4,1.614a7.045,7.045,0,0,0-1.249-.11Z" transform="translate(4.369)" fill="#252525"/>
      <path id="euro" d="M13.974.015c.407.058.813.1,1.22.174a10.509,10.509,0,0,1,5.491,2.847.272.272,0,0,1,.073.2c-.218.567-.45,1.118-.683,1.67C17.242,1.976,13.916.988,10.037,2.426A8.628,8.628,0,0,0,4.823,7.975H18.782c-.16.407-.32.77-.479,1.148-.087.2-.131.494-.291.6s-.421.029-.654.029H4.445V11.5H17.3c-.247.6-.479,1.148-.712,1.7-.015.044-.1.073-.16.073a4.075,4.075,0,0,1-.421,0H4.808a8.606,8.606,0,0,0,5.549,5.68c3.733,1.235,6.943.232,9.66-2.571,0,.029.015.087.015.145v2.106a.413.413,0,0,1-.131.276,10.372,10.372,0,0,1-5.317,2.252l-.654.087H12.652c-.334-.044-.654-.073-.988-.131a10.473,10.473,0,0,1-7.641-5.273,10.037,10.037,0,0,1-1.031-2.557H0v-.044c.16-.349.305-.712.465-1.06.087-.218.131-.508.305-.625s.45-.044.683-.044h1.22V9.747H0V9.7c.218-.508.45-1.017.654-1.54a.259.259,0,0,1,.291-.189c.465.015.93,0,1.394,0h.654A10.635,10.635,0,0,1,5.563,3.326,10.378,10.378,0,0,1,11.185.218C11.65.131,12.129.073,12.594,0c.465.015.93.015,1.38.015" transform="translate(16.989 28.689)" fill="#252525"/>
    </g>
  </svg>
</template>

<style lang="scss" scoped></style>
